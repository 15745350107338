import type {ComponentType} from 'react';

import ReactDOMServer from 'react-dom/server';

/**
 * Generates a data URL with an SVG icon rendered as a background image.
 * This function takes an SVG Icon component and an optional props object as parameters.
 * It renders the Icon component with the provided props using ReactDOMServer.renderToStaticMarkup,
 * and then encodes the resulting SVG markup into a data URL.
 * The generated data URL can be used as a background image value in CSS or inline styles.
 *
 * @param Icon  The SVG Icon component to be rendered as a background image.
 * @param props The optional props object to be passed to the Icon component.
 * @returns The data URL representing the SVG icon rendered as a background image.
 *
 * @example
 * ```tsx
 * const backgroundImage = iconAsBackground(MyIconComponent, { fill: 'red' });
 * // Use `backgroundImage` in CSS or inline styles
 * ```
 */
export const iconAsBackground = (Icon: ComponentType, props: object = {}): string => `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
    // eslint-disable-next-line react/jsx-filename-extension, react/jsx-props-no-spreading
    ReactDOMServer.renderToStaticMarkup(<Icon {...props} />)
)}`;

export const DateFormatter = new Intl.DateTimeFormat('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
});