/* eslint-disable max-len */
import React from 'react';

interface RectangleProps {
    className?: string;
    color1?: string;
    height?: number | string;
    testId?: string;
    width?: number | string;
}

export const Rectangle = React.forwardRef<SVGSVGElement, RectangleProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 16 16"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <rect fill="transparent" height="16" rx="1" stroke={color1} strokeWidth="3" width="16" />
        </svg>
    );
});

Rectangle.displayName = 'Rectangle';
Rectangle.defaultProps = {
    className: '',
    color1: '#192630',
    height: 16,
    testId: '',
    width: 16
};