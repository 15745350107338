import {useCallback, useMemo, useRef} from 'react';
import type {Dispatch, SetStateAction} from 'react';

import {modalFactory} from 'Client/ui/components/layout/Modal';

/**
 * Custom hook for managing modal state and functionality.
 *
 * This hook provides a way to manage modal state and functionality in a component.
 * It returns an object containing the `openModal`, `closeModal`, and `Modal` properties.
 *
 * @returns An object with modal state and functionality.
 *
 * @example
 * ```tsx
 * const { openModal, closeModal, Modal } = useModal();
 * ```
 */
export const useModal = () => {
    const state = useRef<Dispatch<SetStateAction<boolean>>>(null);
    const Modal = useMemo(() => modalFactory(state), []);

    /**
     * Opens the modal.
     *
     * This function sets the state of the modal to `true`, opening the modal.
     */
    const openModal = useCallback(() => {
        if (state.current) {
            state.current(true);
        }
    }, [state]);

    /**
     * Closes the modal.
     *
     * This function sets the state of the modal to `false`, closing the modal.
     *
     * @param onClose An optional callback function to be called when the modal is closed.
     */
    const closeModal = useCallback((onClose?: () => void) => {
        if (state.current) {
            if (typeof onClose === 'function') {
                onClose();
            }

            state.current(false);
        }
    }, [state]);

    return {
        closeModal,
        Modal,
        openModal
    };
};