/* eslint-disable max-len */
import React from 'react';

interface EditProps {
    className?: string;
    color1?: string;
    height?: number | string;
    testId?: string;
    width?: number | string;
}

export const Edit = React.forwardRef<SVGSVGElement, EditProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M18.2 3.08a2.08 2.08 0 0 1 2.27 3.39l-11.8 11.8-2.93-2.95L17.53 3.53c.2-.2.42-.35.67-.45ZM4.68 16.38l-.5.51-1.11 4.04 4.04-1.1.5-.5-2.93-2.95ZM19 1.42a3.58 3.58 0 0 0-2.53 1.05l-13.5 13.5c-.1.1-.16.2-.2.33l-1.5 5.5a.75.75 0 0 0 .93.92l5.5-1.5c.12-.03.24-.1.33-.19l13.5-13.5A3.58 3.58 0 0 0 19 1.43Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Edit.displayName = 'Edit';
Edit.defaultProps = {
    className: '',
    color1: '#0019A5',
    height: 24,
    testId: '',
    width: 24
};