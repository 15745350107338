/* eslint-disable no-param-reassign */
import type {Dispatch, RefObject, SetStateAction} from 'react';

import {spacing} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled, {keyframes} from 'styled-components';

import {IconButton} from '../action/buttons/IconButton';

import {ModalEnterExitAnimation} from 'Client/ui/animations/modals';

import {useModalAnimation} from './hooks/useModalAnimation';
import {ButtonIcons} from 'Client/ui/assets/images/icons';

import type {WithChildren} from 'types/global';

/**
 * Factory function for creating a modal component.
 *
 * This function creates a modal component that can be used to display content
 * in a modal dialog. The modal component supports animation and can be
 * controlled using the `openModal` and `closeModal` functions returned by
 * this factory.
 *
 * @param state Ref object for managing the modal state.
 * @returns The modal component.
 *
 * @example
 * ```tsx
 * const state = useRef<Dispatch<SetStateAction<boolean>>>(null);
 * const Modal = modalFactory(state);
 * ```
 */
const modalFactory = (state: RefObject<Dispatch<SetStateAction<boolean>>>) => {
    interface ComponentProps {
        padding: number;
        /**
         * The size of the component.
         * Available options: `'default'` or `'small'`.
         *
         * @default 'default'
         */
        size: 'default' | 'small';
        /** The test id. */
        testId: string;
    }

    /**
     * Modal component.
     *
     * This component represents a modal dialog that can be used to display
     * content in a modal overlay. It manages the modal state and supports
     * animation when showing or hiding the modal.
     *
     * @param props          Component props.
     * @param props.size     The size of the component.
     * @param props.testId   The test id.
     * @param props.children The children to be rendered inside the modal.
     * @param props.padding  The padding of the modal.
     * @returns The modal component.
     */
    const Modal = ({children, padding, size, testId}: WithChildren<ComponentProps>) => {
        const {controls, modal, setOpen, showContent} = useModalAnimation(state);

        return (
            <Wrapper
                ref={modal}
                $padding={padding}
                $size={size}
                animate={controls}
                data-cy={testId}
                variants={ModalEnterExitAnimation}
            >
                <CloseWrapper onClick={() => setOpen(false)}>
                    <IconButton
                        icon={ButtonIcons.Close}
                        size="small"
                        style="light"
                        onClick={() => setOpen(false)}
                    />
                </CloseWrapper>
                {showContent && children}
            </Wrapper>
        );
    };

    Modal.displayName = 'Modal';
    Modal.defaultProps = {
        padding: 0,
        size: 'default',
        testId: undefined
    };

    return Modal;
};

export {modalFactory};

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

interface WrapperProps {
    $padding: number;
    $size: 'default' | 'small';
}

const Wrapper = styled(motion.dialog)<WrapperProps>`
    align-content: stretch;
    background: ${({theme}) => theme.colors.contentBackgroundColor};
    border: none;
    border-radius: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: max-content;
    max-height: 86%;
    max-width: ${({$size}) => ($size === 'small' ? '66rem' : '106.4rem')};
    min-height: 20rem;
    opacity: 0;
    outline: none;
    overflow: hidden;
    padding: ${({$padding, theme}) => spacing($padding, theme)};
    transform: translateY(80px);
    width: 95%;

    &::backdrop {
        animation: ${fadeIn} 0.3s ease-in-out backwards;
        animation-direction: reverse;
        background-color: rgba(0, 0, 0, 0.7);
    }

    &[open]::backdrop {
        animation: ${fadeIn} 0.3s ease-in-out forwards;
    }
`;

const CloseWrapper = styled.div`
    position: absolute;
    right: ${spacing(5)};
    top: ${spacing(5)};
    z-index: 10;
`;