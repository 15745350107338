/* eslint-disable max-len */
import React from 'react';

interface EditSmallProps {
    className?: string;
    color1?: string;
    height?: number | string;
    testId?: string;
    width?: number | string;
}

export const EditSmall = React.forwardRef<SVGSVGElement, EditSmallProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M19.03 3.03a1.37 1.37 0 0 1 1.94 1.94l-9.35 9.35-2.59.65.65-2.59 9.35-9.35Zm.97-1.9c-.76 0-1.5.3-2.03.84l-9.5 9.5c-.1.1-.16.22-.2.35l-1 4a.75.75 0 0 0 .91.9l4-1a.8.8 0 0 0 .35-.19l9.5-9.5A2.87 2.87 0 0 0 20 1.13ZM4 3.25A2.75 2.75 0 0 0 1.25 6v14A2.75 2.75 0 0 0 4 22.75h14A2.75 2.75 0 0 0 20.75 20v-7a.75.75 0 0 0-1.5 0v7A1.25 1.25 0 0 1 18 21.25H4A1.25 1.25 0 0 1 2.75 20V6A1.25 1.25 0 0 1 4 4.75h7a.75.75 0 0 0 0-1.5H4Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

EditSmall.displayName = 'EditSmall';
EditSmall.defaultProps = {
    className: '',
    color1: '#0019A5',
    height: 24,
    testId: '',
    width: 24
};