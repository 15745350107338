/* eslint-disable max-len */
import React from 'react';

interface HandProps {
    className?: string;
    color1?: string;
    height?: number | string;
    testId?: string;
    width?: number | string;
}

export const Hand = React.forwardRef<SVGSVGElement, HandProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 21 21"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M15.86 10.85V5.26m0 0V3.18a1.38 1.38 0 0 0-2.77 0m2.77 2.08a1.38 1.38 0 1 1 2.76 0v10.42a4.56 4.56 0 0 1-4.56 4.55H10.1a2.5 2.5 0 0 1-1.5-.5l-.6-.64-4.66-5.23c-.66-.74-.6-1.89.14-2.55a1.82 1.82 0 0 1 2.48.06l1.62 1.59V4.27a1.37 1.37 0 1 1 2.75 0m2.76 6.58V3.18m0 0V2.15a1.38 1.38 0 1 0-2.76 0v2.12m0 6.58V4.27" fill="transparent" stroke={color1} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.31" />
        </svg>
    );
});

Hand.displayName = 'Hand';
Hand.defaultProps = {
    className: '',
    color1: '#0019A5',
    height: 21,
    testId: '',
    width: 21
};