export type FontSize = 'large' | 'medium' | 'small';

interface GetSizeParams {
    $size?: FontSize;
}

/**
 * Retrieves the font size value based on the provided size parameter.
 * This function takes in an object with a $size property and returns the corresponding font size value.
 * The font size values are determined based on the provided size parameter:
 * 'large': Returns '1.6rem'.
 * 'small': Returns '1.2rem'.
 * 'medium' (default): Returns '1.4rem'.
 *
 * @param props       The styled components props.
 * @param props.$size The size parameter used to determine the font size value.
 * @returns The font size value based on the provided size parameter.
 *
 * @example
 * ```tsx
 * const fontSize = getPSize({ $size: 'large' }); // Returns '1.6rem'
 * ```
 */
export const getPSize = ({$size}: GetSizeParams) => {
    switch ($size) {
        case 'large':
            return '1.6rem';
        case 'small':
            return '1.2rem';
        case 'medium':
        default:
            return '1.4rem';
    }
};

/**
 * Retrieves the line height value based on the provided size parameter.
 * This function takes in an object with a $size property and returns the corresponding line height value.
 * The line height values are determined based on the provided size parameter:
 * 'large': Returns '2.4rem'.
 * 'small': Returns '1.6rem'.
 * 'medium' (default): Returns '2rem'.
 *
 * @param props       The styled components props.
 * @param props.$size The size parameter used to determine the line height value.
 * @returns The line height value based on the provided size parameter.
 *
 * @example
 * ```tsx
 * const lineHeight = getPLineHeight({ $size: 'large' }); // Returns '2.4rem'
 * ```
 */
export const getPLineHeight = ({$size}: GetSizeParams) => {
    switch ($size) {
        case 'large':
            return '2.4rem';
        case 'small':
            return '1.6rem';
        case 'medium':
        default:
            return '2rem';
    }
};