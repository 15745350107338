/* eslint-disable max-len */
import React from 'react';

interface MousePointerProps {
    className?: string;
    color1?: string;
    height?: number | string;
    testId?: string;
    width?: number | string;
}

export const MousePointer = React.forwardRef<SVGSVGElement, MousePointerProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 21 21"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="m9.08 19-.66.27a.72.72 0 0 0 1.34-.04L9.08 19ZM2 2l.27-.66a.71.71 0 0 0-.93.93L2 2Zm9.45 10.04.67.23-.67-.23Zm.59-.6.23.68-.23-.67ZM19 9.09l.23.68a.71.71 0 0 0 .04-1.34l-.27.66Zm-9.26 9.65-7.08-17-1.32.54 7.08 17 1.32-.54Zm1.03-6.92-2.36 6.96 1.35.46 2.36-6.96-1.35-.46Zm1.04-1.04c-.49.17-.87.55-1.04 1.04l1.35.46a.23.23 0 0 1 .15-.15l-.46-1.35Zm6.96-2.36-6.96 2.36.46 1.35 6.96-2.36-.46-1.35ZM1.73 2.66l17 7.08.54-1.32-17-7.08-.54 1.32Z" fill={color1} />
            <path d="m12 12 6 6" stroke={color1} strokeLinecap="round" strokeWidth="1.43" />
        </svg>
    );
});

MousePointer.displayName = 'MousePointer';
MousePointer.defaultProps = {
    className: '',
    color1: '#0019A5',
    height: 21,
    testId: '',
    width: 21
};