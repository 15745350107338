/* eslint-disable max-len */
import React from 'react';

interface NewTextProps {
    className?: string;
    color1?: string;
    color2?: string;
    height?: number | string;
    testId?: string;
    width?: number | string;
}

export const NewText = React.forwardRef<SVGSVGElement, NewTextProps>((props, ref) => {
    const {className, color1, color2, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 30 27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <rect fill="transparent" height="19.5" rx="1.25" stroke={color1} strokeWidth="1.5" width="18.5" x="5.75" y="3.75" />
            <path d="M15.86 18h-1.37V9.43H11.4v-.56c0-.32.18-.5.5-.5h7.03v1.06h-3.08V18Z" fill={color1} />
            <circle cx="6" cy="21" fill={color2} r="5" />
            <path clipRule="evenodd" d="M6 16.38a4.63 4.63 0 1 0 0 9.25 4.63 4.63 0 0 0 0-9.25ZM.62 21a5.38 5.38 0 1 1 10.76 0A5.38 5.38 0 0 1 .62 21ZM6 18.62c.2 0 .38.17.38.38v1.63H8a.38.38 0 0 1 0 .75H6.37V23a.38.38 0 0 1-.75 0v-1.63H4a.38.38 0 0 1 0-.75h1.63V19c0-.2.16-.38.37-.38Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

NewText.displayName = 'NewText';
NewText.defaultProps = {
    className: '',
    color1: '#192630',
    color2: '#E5EAEE',
    height: 27,
    testId: '',
    width: 30
};