/* eslint-disable max-len */
import React from 'react';

interface AssetUpProps {
    className?: string;
    color1?: string;
    height?: number | string;
    testId?: string;
    width?: number | string;
}

export const AssetUp = React.forwardRef<SVGSVGElement, AssetUpProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 37 21"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill="transparent" stroke={color1} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.43">
                <path d="m25.77.95-9.54 4.78 9.54 4.77 9.55-4.77L25.77.95Zm-9.54 14.32 9.54 4.78 9.55-4.78M16.23 10.5l9.54 4.77 9.55-4.77M10.5 9.55 5.73 4.77.95 9.55m4.78 6.68V4.77" />
            </g>
        </svg>
    );
});

AssetUp.displayName = 'AssetUp';
AssetUp.defaultProps = {
    className: '',
    color1: '#0019A5',
    height: 21,
    testId: '',
    width: 37
};