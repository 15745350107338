/* eslint-disable max-len */
import React from 'react';

interface ChevronLeftProps {
    className?: string;
    color1?: string;
    height?: number | string;
    testId?: string;
    width?: number | string;
}

export const ChevronLeft = React.forwardRef<SVGSVGElement, ChevronLeftProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            style={{transform: 'rotate(-90deg)'}}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M19.53 16.53c-.3.3-.77.3-1.06 0L12 10.06l-6.47 6.47a.75.75 0 0 1-1.06-1.06l7-7c.3-.3.77-.3 1.06 0l7 7c.3.3.3.77 0 1.06Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

ChevronLeft.displayName = 'ChevronLeft';
ChevronLeft.defaultProps = {
    className: '',
    color1: '#0019A5',
    height: 24,
    testId: '',
    width: 24
};