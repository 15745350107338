/* eslint-disable import/max-dependencies */
import {Add} from './Add';
import {Archive} from './Archive';
import {AssetDown} from './AssetDown';
import {AssetUp} from './AssetUp';
import {Camera} from './Camera';
import {Check} from './Check';
import {CheckSmall} from './CheckSmall';
import {ChevronDown} from './ChevronDown';
import {ChevronLeft} from './ChevronLeft';
import {ChevronRight} from './ChevronRight';
import {ChevronUp} from './ChevronUp';
import {Close} from './Close';
import {Edit} from './Edit';
import {EditSmall} from './EditSmall';
import {Ellipse} from './Ellipse';
import {Eye} from './Eye';
import {FontDown} from './FontDown';
import {FontUp} from './FontUp';
import {Fullscreen} from './Fullscreen';
import {Hand} from './Hand';
import {Help} from './Help';
import {Home} from './Home';
import {Info} from './Info';
import {Logo} from './Logo';
import {LogoBlau} from './LogoBlau';
import {LogoO2} from './LogoO2';
import {LogoTEF} from './LogoTEF';
import {LogOut} from './LogOut';
import {Menu} from './Menu';
import {Minus} from './Minus';
import {MissingFile} from './MissingFile';
import {MissingFileSad} from './MissingFileSad';
import {MousePointer} from './MousePointer';
import {NewText} from './NewText';
import {Play} from './Play';
import {Plus} from './Plus';
import {Rectangle} from './Rectangle';
import {Save} from './Save';
import {Search} from './Search';
import {ShapeColor} from './ShapeColor';
import {Text} from './Text';
import {TextColor} from './TextColor';
import {Trash} from './Trash';
import {Warning} from './Warning';

enum ButtonIcons {
    'Add' = 'Add',
    'Archive' = 'Archive',
    'AssetDown' = 'AssetDown',
    'AssetUp' = 'AssetUp',
    'Camera' = 'Camera',
    'ChevronLeft' = 'ChevronLeft',
    'ChevronRight' = 'ChevronRight',
    'Close' = 'Close',
    'Edit' = 'Edit',
    'EditSmall' = 'EditSmall',
    'Ellipse' = 'Ellipse',
    'Eye' = 'Eye',
    'Hand' = 'Hand',
    'LogOut' = 'LogOut',
    'Menu' = 'Menu',
    'Minus' = 'Minus',
    'MousePointer' = 'MousePointer',
    'NewText' = 'NewText',
    'Play' = 'Play',
    'Plus' = 'Plus',
    'Rectangle' = 'Rectangle',
    'Save' = 'Save',
    'Search' = 'Search',
    'ShapeColor' = 'ShapeColor',
    'Text' = 'Text',
    'TextColor' = 'TextColor',
    'Trash' = 'Trash'
}

export {
    Add,
    Archive,
    AssetDown,
    AssetUp,
    ButtonIcons,
    Camera,
    Check,
    CheckSmall,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    ChevronUp,
    Close,
    Edit,
    EditSmall,
    Ellipse,
    Eye,
    FontDown,
    FontUp,
    Fullscreen,
    Hand,
    Help,
    Home,
    Info,
    Logo,
    LogoBlau,
    LogoO2,
    LogoTEF,
    LogOut,
    Menu,
    Minus,
    MissingFile,
    MissingFileSad,
    MousePointer,
    NewText,
    Play,
    Plus,
    Rectangle,
    Save,
    Search,
    ShapeColor,
    Text,
    TextColor,
    Trash,
    Warning
};