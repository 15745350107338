/* eslint-disable no-nested-ternary */
import type {MouseEvent} from 'react';

import styled from 'styled-components';

import type {DefaultTheme} from 'styled-components';
import type {WithChildren} from 'types/global';

interface ComponentProps {
    /**
     * Determines whether the button has a slim border.
     * When set to `true`, the button will be visually appear with a slim border.
     */
    hasSlimBorder: boolean;
    /**
     * Determines whether the button is active.
     * When set to `true`, the button will be visually appear active.
     * This is useful for indicating the current state of the button.
     * For example, when the button is toggled on.
     * This is only applicable to buttons that can be toggled.
     *
     * @default false
     */
    isActive: boolean;
    /**
     * Determines whether the button is disabled.
     * When set to `true`, the button will be non-interactive and visually appear disabled.
     */
    isDisabled: boolean;
    /**
     * Determines whether the button is rounded.
     * When set to `true`, the button will be visually appear rounded.
     */
    isRounded: boolean;
    /**
     * Event handler for the button's click event.
     * This function will be called when the button is clicked.
     *
     * @param event The mouse event object associated with the click event.
     */
    onClick(event: MouseEvent <HTMLButtonElement>): void;
    /**
     * The style variant of the button.
     * This determines the visual style of the button.
     * Available options: `'dark'` (default) or `'light'`.
     *
     * @default 'dark'
     */
    style: 'dark' | 'light';
    /**
     * The test id for the button.
     * This can be used for identifying the button in tests.
     */
    testId: string;
    /**
     * The type of the button.
     * This determines the behavior of the button when clicked.
     * Available options: `'button'` (default), `'reset'`, or `'submit'`.
     *
     * @default 'button'
     */
    type: 'button' | 'reset' | 'submit';
    /**
     * The variant of the button.
     * This determines the visual variant of the button.
     * Available options: `'primary'` (default) or `'secondary'`.
     *
     * @default 'primary'
     */
    variant: 'primary' | 'secondary';
}

/**
 * The ButtonBase component is a base styled button component that can be customized with different variants and styles.
 *
 * @param props               Component props.
 * @param props.children      The children.
 * @param props.hasSlimBorder If the button has a slim border.
 * @param props.isActive      If the button is active.
 * @param props.isDisabled    If the button is disabled.
 * @param props.isRounded     If the button is rounded.
 * @param props.onClick       The onClick function.
 * @param props.style         The style of the button.
 * @param props.testId        The test id.
 * @param props.type          The type of the button.
 * @param props.variant       The variant of the button.
 * @returns The component.
 *
 * @example
 * ```tsx
 * <ButtonBase
 *     isDisabled={false}
 *     onClick={handleClick}
 *     style="dark"
 *     testId="example-button"
 *     type="button"
 *     variant="primary"
 * >
 *     Click me
 * </ButtonBase>
 * ```
 */
const ButtonBase = ({
    children,
    hasSlimBorder,
    isActive,
    isDisabled,
    isRounded,
    onClick,
    style,
    testId,
    type,
    variant
}: WithChildren<ComponentProps>) => (
    <Wrapper
        $hasSlimBorder={hasSlimBorder}
        $isRounded={isRounded}
        $style={style}
        $variant={variant}
        className={isActive ? 'active' : ''}
        data-cy={testId}
        disabled={isDisabled}
        type={type}
        onClick={onClick}
    >
        {children}
    </Wrapper>
);

ButtonBase.displayName = 'ButtonBase';
ButtonBase.defaultProps = {
    hasSlimBorder: false,
    isActive: false,
    isDisabled: false,
    isRounded: false,
    /**
     * Default onClick function.
     */
    onClick() {},
    // eslint-disable-next-line @nfq/sort-keys
    style: 'dark',
    testId: undefined,
    type: 'button',
    variant: 'primary'
};

export {ButtonBase};

interface WrapperProps {
    $hasSlimBorder: boolean;
    $isRounded: boolean;
    $style: 'dark' | 'light';
    $variant: 'primary' | 'secondary';
}

/**
 * `getActiveBorderColor` is a utility function that determines the active border color for a button based on various properties.
 * It takes an object containing a `$hasSlimBorder` boolean, a `$style` string, and a `theme` object of type `DefaultTheme`.
 * The function returns the appropriate border color based on the given properties and the theme.
 *
 * @param props                The props for the component.
 * @param props.$hasSlimBorder A boolean indicating whether the button has a slim border.
 * @param props.$style         A string representing the style of the button ('dark' or other).
 * @param props.theme          An object of type `DefaultTheme` containing theme-related properties.
 * @returns A string representing the color code for the active border.
 *
 * @example
 * ```tsx
 * const borderColor = getActiveBorderColor({ $hasSlimBorder: true, $style: 'dark', theme: someTheme });
 * ```
 */
const getActiveBorderColor = ({$hasSlimBorder, $style, theme}: WrapperProps & {theme: DefaultTheme}) => {
    if ($hasSlimBorder) {
        return theme.colors.buttonBorderColor;
    }

    return $style === 'dark' ? theme.colors.buttonDarkPressedColor : theme.colors.buttonLightPressedColor;
};

const Wrapper = styled.button<WrapperProps>`
    background-color: ${({$style, $variant, theme}) => ($variant === 'secondary' ? 'transparent' : $style === 'dark' ? theme.colors.buttonDarkColor : theme.colors.buttonLightColor)};
    border: ${({$hasSlimBorder}) => ($hasSlimBorder ? '1px' : '2px')} solid ${({$style, $variant, theme}) => ($variant === 'secondary' ? 'transparent' : $style === 'dark' ? theme.colors.buttonDarkColor : theme.colors.buttonLightColor)};
    border-radius: ${({$isRounded}) => ($isRounded ? '50%' : '4px')};
    cursor: pointer;
    outline: none;
    padding: 0;
    transition: background-color 0.3s 0s ease-in-out, border 0.3s 0s ease-in-out, opacity 0.3s 0s ease-in-out;
    will-change: background-color, border, opacity;

    &:hover {
        background-color: ${({$style, theme}) => ($style === 'dark' ? theme.colors.buttonDarkHoverColor : theme.colors.buttonLightHoverColor)};
        border: ${({$hasSlimBorder}) => ($hasSlimBorder ? '1px' : '2px')} solid ${({$style, theme}) => ($style === 'dark' ? theme.colors.buttonDarkHoverColor : theme.colors.buttonLightHoverColor)};
        outline: none;
        transition: border 0.3s 0s ease-in-out, background-color 0.3s 0s ease-in-out;
    }
    &:active, &.active {
        background-color: ${({$style, theme}) => ($style === 'dark' ? theme.colors.buttonDarkPressedColor : theme.colors.buttonLightPressedColor)};
        border: ${({$hasSlimBorder}) => ($hasSlimBorder ? '1px' : '2px')} solid ${getActiveBorderColor};
        outline: none;
        transition: border 0.3s 0s ease-in-out, background-color 0.3s 0s ease-in-out;
    }
    &:focus {
        border: ${({$hasSlimBorder}) => ($hasSlimBorder ? '1px' : '2px')} solid ${({theme}) => theme.colors.buttonBorderColor};
        outline: none;
        transition: border 0.3s 0s ease-in-out;
    }
    &:disabled {
        opacity: 0.3;
        outline: none;
        transition: opacity 0.3s 0s ease-in-out;
    }
`;