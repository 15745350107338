/* eslint-disable max-len */
import React from 'react';

interface CameraProps {
    className?: string;
    color1?: string;
    height?: number | string;
    testId?: string;
    width?: number | string;
}

export const Camera = React.forwardRef<SVGSVGElement, CameraProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M9 2.25a.75.75 0 0 0-.62.33L6.6 5.25H3A2.75 2.75 0 0 0 .25 8v11A2.75 2.75 0 0 0 3 21.75h18A2.75 2.75 0 0 0 23.75 19V8A2.75 2.75 0 0 0 21 5.25h-3.6l-1.78-2.67a.75.75 0 0 0-.62-.33H9ZM7.62 6.42 9.4 3.75h5.2l1.78 2.67c.14.2.37.33.62.33h4A1.25 1.25 0 0 1 22.25 8v11A1.25 1.25 0 0 1 21 20.25H3A1.25 1.25 0 0 1 1.75 19V8A1.25 1.25 0 0 1 3 6.75h4c.25 0 .48-.13.62-.33ZM8.75 13a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0ZM12 8.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Camera.displayName = 'Camera';
Camera.defaultProps = {
    className: '',
    color1: '#0019A5',
    height: 24,
    testId: '',
    width: 24
};