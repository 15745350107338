import {useForegroundColor} from 'Client/ui/components/action/buttons/hooks/useForegroundColor';
import {getIcon, getIconSize} from 'Client/ui/components/action/buttons/utils/utils';

import type {ButtonIcons} from 'Client/ui/assets/images/icons';
import type {Colors} from 'Client/ui/utils/globalStyles';

/**
 * Custom hook for using an icon button.
 * Returns an object containing the necessary properties and values for rendering an icon button component.
 * The icon button can be styled with either a 'dark' or 'light' theme.
 * The size of the icon can be set to 'large' or 'small'.
 * Optionally, an icon can be passed to the icon button using the 'icon' parameter.
 *
 * @param style          The style of the icon button. It can be either 'dark' or 'light'.
 * @param size           The size of the icon button. It can be either 'large' or 'small'.
 * @param icon           An optional icon for the icon button.
 * @param overwriteColor The explicit color of the icon.
 * @returns An object containing the foreground color, Icon component, and icon size for the icon button.
 *
 * @example
 * ```tsx
 * const { foregroundColor, Icon, iconSize } = useIconButton('dark', 'large', ButtonIcons.HOME);
 * ```
 */
export const useIconButton = (
    style: 'dark' | 'light',
    size: 'large' | 'small',
    icon?: ButtonIcons,
    overwriteColor?: Colors
) => {
    const foregroundColor = useForegroundColor(style, overwriteColor);
    const Icon = getIcon(icon)!;
    const iconSize = getIconSize(size, true);

    return {
        foregroundColor,
        Icon,
        iconSize
    };
};