/* eslint-disable max-len */
import React from 'react';

import styled from 'styled-components';

interface ShapeColorProps {
    className?: string;
    secondaryColor?: string;
    testId?: string;
}

export const ShapeColor = React.forwardRef<SVGSVGElement, ShapeColorProps>((props, ref) => {
    const {className, secondaryColor, testId} = props;

    return (
        <Gradient>
            <svg
                ref={ref}
                className={className}
                data-cy={testId}
                height="100%"
                viewBox="0 0 18 18"
                width="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <circle cx="9" cy="9" fill={secondaryColor} r="7" />
            </svg>
        </Gradient>
    );
});

ShapeColor.displayName = 'ShapeColor';
ShapeColor.defaultProps = {
    className: '',
    secondaryColor: '#0090D0',
    testId: ''
};

const Gradient = styled.div`
    align-items: center;
    aspect-ratio: 1;
    display: flex;
    font-size: 0;
    justify-content: center;
    line-height: 0;
    padding: 2px;
    position: relative;
    width: 100%;

    &::before {
        -webkit-mask-composite: xor;
        background: conic-gradient(from 0.50turn at 50% 50%, #000066, #0019A5, #0090D0, #41B6E6, #B1E4E3, #FFC548, #F65D7C, #824CAB, #74CF9A, #39C7C5) border-box;
        border: 1.5px solid transparent;
        border-radius: 50%;
        content: '';
        height: 100%;
        mask:
            linear-gradient(#fff 0 0) padding-box,
            linear-gradient(#fff 0 0);
        mask-composite: exclude;
        position: absolute;
        width: 100%;
    }
`;