/* eslint-disable max-len */
import React from 'react';

interface AssetDownProps {
    className?: string;
    color1?: string;
    height?: number | string;
    testId?: string;
    width?: number | string;
}

export const AssetDown = React.forwardRef<SVGSVGElement, AssetDownProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 37 21"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M10.5.95.95 5.73l9.55 4.77 9.55-4.77L10.5.95ZM.95 15.27l9.55 4.78 9.55-4.78M.95 10.5l9.55 4.77 9.55-4.77m5.25.95 4.77 4.78 4.77-4.78m-4.77-6.68v11.46" fill="transparent" stroke={color1} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.43" />
        </svg>
    );
});

AssetDown.displayName = 'AssetDown';
AssetDown.defaultProps = {
    className: '',
    color1: '#0019A5',
    height: 21,
    testId: '',
    width: 37
};