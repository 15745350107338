/* eslint-disable max-len */
import React from 'react';

interface TextProps {
    className?: string;
    color1?: string;
    height?: number | string;
    testId?: string;
    width?: number | string;
}

export const Text = React.forwardRef<SVGSVGElement, TextProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 12 15"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M7.29 15H5.23V2.15H.6V1.3c0-.48.27-.76.76-.76H11.9v1.6H7.29V15Z" fill={color1} />
        </svg>
    );
});

Text.displayName = 'Text';
Text.defaultProps = {
    className: '',
    color1: '#192630',
    height: 15,
    testId: '',
    width: 12
};