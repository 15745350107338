/* eslint-disable max-len */
import React from 'react';

interface SaveProps {
    className?: string;
    color1?: string;
    height?: number | string;
    testId?: string;
    width?: number | string;
}

export const Save = React.forwardRef<SVGSVGElement, SaveProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M5 3.75A1.25 1.25 0 0 0 3.75 5v14A1.25 1.25 0 0 0 5 20.25h1.25V13c0-.41.34-.75.75-.75h10c.41 0 .75.34.75.75v7.25H19A1.25 1.25 0 0 0 20.25 19V8.31l-4.56-4.56H7.75v3.5H15a.75.75 0 0 1 0 1.5H7A.75.75 0 0 1 6.25 8V3.75H5Zm2-1.5H5A2.75 2.75 0 0 0 2.25 5v14A2.75 2.75 0 0 0 5 21.75h14A2.75 2.75 0 0 0 21.75 19V8c0-.2-.08-.39-.22-.53l-5-5a.75.75 0 0 0-.53-.22H7Zm.75 11.5v6.5h8.5v-6.5h-8.5Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Save.displayName = 'Save';
Save.defaultProps = {
    className: '',
    color1: '#0019A5',
    height: 24,
    testId: '',
    width: 24
};