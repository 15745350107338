import type {Variants} from 'framer-motion';

export const ModalEnterExitAnimation: Variants = {
    enter: {
        opacity: 1,
        y: '0px'
    },
    exit: {
        opacity: 0,
        y: '80px'
    }
};

export const ModalStepAnimation: Variants = {
    enter: {x: '0%'},
    /**
     * Exit Variant.
     *
     * This variant defines the animation properties for the exit transition
     * of the modal step. The direction of the exit animation is determined by
     * the provided `dir` parameter. If `dir` is set to 'backwards', the x-axis
     * position is set to '100%'. If `dir` is set to 'forwards', the x-axis
     * position is set to '-100%'.
     *
     * @param dir The direction of the exit animation ('backwards' or 'forwards').
     *
     * @returns The animation properties for the exit transition.
     */
    exit(dir: 'backwards' | 'forwards') {
        if (dir === 'backwards') {
            return {x: '100%'};
        }

        return {x: '-100%'};
    },
    /**
     * Initial Variant.
     *
     * This variant defines the initial animation properties for the modal step.
     * The direction of the initial animation is determined by the provided `dir`
     * parameter. If `dir` is set to 'backwards', the x-axis position is set to
     * '-100%'. If `dir` is set to 'forwards', the x-axis position is set to '100%'.
     *
     * @param dir The direction of the initial animation ('backwards' or 'forwards').
     *
     * @returns The initial animation properties for the modal step.
     */
    initial(dir: 'backwards' | 'forwards') {
        if (dir === 'backwards') {
            return {x: '-100%'};
        }

        return {x: '100%'};
    }
};