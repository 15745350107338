/* eslint-disable max-len */
import React from 'react';

interface AddProps {
    className?: string;
    color1?: string;
    height?: number | string;
    testId?: string;
    width?: number | string;
}

export const Add = React.forwardRef<SVGSVGElement, AddProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 21 21"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M10.5 1.67a8.83 8.83 0 1 0 0 17.66 8.83 8.83 0 0 0 0-17.66ZM.24 10.5a10.26 10.26 0 1 1 20.52 0 10.26 10.26 0 0 1-20.52 0ZM10.5 5.97c.4 0 .72.32.72.71v3.1h3.1a.72.72 0 1 1 0 1.44h-3.1v3.1a.72.72 0 1 1-1.44 0v-3.1h-3.1a.72.72 0 1 1 0-1.44h3.1v-3.1c0-.4.32-.71.72-.71Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Add.displayName = 'Add';
Add.defaultProps = {
    className: '',
    color1: '#0019A5',
    height: 21,
    testId: '',
    width: 21
};