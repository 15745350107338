import type {ButtonIcons} from 'Client/ui/assets/images/icons';
import {
    Add,
    Archive,
    AssetDown,
    AssetUp,
    Camera,
    ChevronLeft,
    ChevronRight,
    Close,
    Edit,
    EditSmall,
    Ellipse,
    Eye,
    Hand,
    LogOut,
    Menu,
    Minus,
    MousePointer,
    NewText,
    Play,
    Plus,
    Rectangle,
    Save,
    Search,
    ShapeColor,
    Text,
    TextColor,
    Trash
} from 'Client/ui/assets/images/icons';

const Icons = {
    Add,
    Archive,
    AssetDown,
    AssetUp,
    Camera,
    ChevronLeft,
    ChevronRight,
    Close,
    Edit,
    EditSmall,
    Ellipse,
    Eye,
    Hand,
    LogOut,
    Menu,
    Minus,
    MousePointer,
    NewText,
    Play,
    Plus,
    Rectangle,
    Save,
    Search,
    ShapeColor,
    Text,
    TextColor,
    Trash
};

/**
 * Returns the icon corresponding to the provided ButtonIcons enum value.
 * If no icon value is provided, null is returned.
 * Otherwise, the icon associated with the provided enum value is retrieved from the Icons object.
 *
 * @param icon The ButtonIcons enum value representing the desired icon.
 * @returns The icon corresponding to the provided enum value, or null if no icon value is provided.
 *
 * @example
 * ```ts
 * const icon = getIcon(ButtonIcons.HOME);
 * ```
 */
export const getIcon = (icon?: ButtonIcons) => (
    // eslint-disable-next-line security/detect-object-injection
    icon === undefined ? null : Icons[icon]
);

/**
 * Returns the size of an icon based on the provided size parameter.
 * The icon can be either 'large' or 'small'.
 * By default, the icon size is set to 14.
 * If the size parameter is 'large' or the single flag is set to true, the size will be 21.
 *
 * @param size   The size of the icon. It can be either 'large' or 'small'.
 * @param single A flag indicating whether the icon should be displayed as a single icon.
 * @returns The size of the icon based on the provided parameters.
 *
 * @example
 * ```ts
 * const iconSize = getIconSize('large', true);
 * ```
 */
export const getIconSize = (size: 'large' | 'small', single = false) => (
    // eslint-disable-next-line @nfq/no-magic-numbers
    (size === 'large' || single) ? 21 : 14
);

/**
 * Returns the CSS property value for the icon position based on the provided position parameter.
 * The position can be either 'left' or 'right'.
 * If the position is 'right', the CSS property value 'row-reverse' is returned.
 * Otherwise, the CSS property value 'row' is returned.
 *
 * @param position The position of the icon. It can be either 'left' or 'right'.
 * @returns The CSS property value for the icon position based on the provided position parameter.
 *
 * @example
 * ```ts
 * const iconPosition = getIconPosition('right');
 * ```
 */
export const getIconPosition = (position: 'left' | 'right') => (
    position === 'right' ? 'row-reverse' as const : 'row' as const
);

/**
 * Returns the size value for the button text based on the provided size parameter.
 * The size can be either 'large', 'medium', or 'small'.
 * If the size is 'large', the value 'large' is returned.
 * Otherwise, the value 'medium' is returned.
 *
 * @param size The size of the button text. It can be either 'large', 'medium', or 'small'.
 * @returns The size value for the button text based on the provided size parameter.
 *
 * @example
 * ```ts
 * const textSize = getButtonTextSize('large');
 * ```
 */
export const getButtonTextSize = (size: 'large' | 'medium' | 'small') => (
    size === 'large' ? 'large' as const : 'medium' as const
);