/* eslint-disable max-len */
import React from 'react';

interface TextColorProps {
    className?: string;
    color1?: string;
    height?: number | string;
    secondaryColor?: string;
    testId?: string;
    width?: number | string;
}

export const TextColor = React.forwardRef<SVGSVGElement, TextColorProps>((props, ref) => {
    const {className, color1, height, secondaryColor, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 16 18"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M8.55 12H6.98V2.2H3.46v-.63c0-.37.21-.58.58-.58h8.03v1.22H8.55V12Z" fill={color1} />
            <path d="M0 16h16v2H0v-2Z" fill={secondaryColor} />
        </svg>
    );
});

TextColor.displayName = 'TextColor';
TextColor.defaultProps = {
    className: '',
    color1: '#192630',
    height: 18,
    secondaryColor: '#ff0000',
    testId: '',
    width: 16
};