/* eslint-disable max-len */
import React from 'react';

interface ArchiveProps {
    className?: string;
    color1?: string;
    height?: number | string;
    testId?: string;
    width?: number | string;
}

export const Archive = React.forwardRef<SVGSVGElement, ArchiveProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M3 7.25c.41 0 .75.34.75.75v12.25h16.5V8a.75.75 0 0 1 1.5 0v13c0 .41-.34.75-.75.75H3a.75.75 0 0 1-.75-.75V8c0-.41.34-.75.75-.75Z" fill={color1} fillRule="evenodd" />
            <path clipRule="evenodd" d="M.25 3c0-.41.34-.75.75-.75h22c.41 0 .75.34.75.75v5c0 .41-.34.75-.75.75H1A.75.75 0 0 1 .25 8V3Zm1.5.75v3.5h20.5v-3.5H1.75ZM9.25 12c0-.41.34-.75.75-.75h4a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.75-.75Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Archive.displayName = 'Archive';
Archive.defaultProps = {
    className: '',
    color1: '#0019A5',
    height: 24,
    testId: '',
    width: 24
};